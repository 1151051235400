import React from "react"
import GoogleMapReact from "google-map-react"
import { css } from "@emotion/react"
import Badge from "../images/corrigan-gore-icon.png"

const defaultProps = {
  center: {
    lat: 51.38473,
    lng: -2.35338,
  },
  zoom: 17,
  styles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5",
        },
      ],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5",
        },
      ],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ],
}

const BathMapMarker = ({ corriganBath, goreBath }) => (
  <div className="google-place-name">
    <span className="first-name">{corriganBath}</span>
    {goreBath}
  </div>
)

const BadgeMarker = () => (
  <div className="marker">
    <img
      css={css`
        width: 40px;
        height: 40px;
      `}
      src={Badge}
    />
  </div>
)

const GoogleMapBath = () => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: "AIzaSyA2LFi7qOycIt_ote1AiVA8K1ZneZRd3E4" }}
    defaultCenter={defaultProps.center}
    defaultZoom={defaultProps.zoom}
    options={{ styles: defaultProps.styles }}
  >
    {/* <BathMapMarker
      lat={51.384}
      lng={-2.353375}
      corriganBath={"Corrigan"}
      goreBath={"Gore"}
    /> */}

    <BadgeMarker lat={51.38473} lng={-2.35338} badgeMarker={""} />
  </GoogleMapReact>
)

export default GoogleMapBath
