import React, { useEffect } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { css } from "@emotion/react"
import SEOContact from "../components/seo-contact"
import GoogleMap from "../components/googleMap"
import GoogleMapBath from "../components/googleMapBath"
import parse from "html-react-parser"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { device } from "../components/device"
// import { GatsbyImage } from "gatsby-plugin-image";
const spacingH = "clamp(1.2rem, 4vw, 6rem)"

const ContactPage = ({
  data: {
    wpPage: { title, content, id },
  },
}) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const intros = gsap.utils.toArray(".fade-intro")
    intros.forEach((intro, i) => {
      const anim = gsap.fromTo(
        intro,
        { autoAlpha: 0, y: 50 },
        { duration: 1, autoAlpha: 1, y: 0, stagger: 0.75, ease: "power1.inOut" }
      )
      ScrollTrigger.create({
        trigger: intro,
        animation: anim,
        toggleActions: "play none none none",
      })
    })
  }, [])

  return (
    <Layout>
      <SEOContact title={title} />

      <div
        css={css`
          display: grid;
          padding: 12rem ${spacingH} 6rem;
          gap: 3rem;
          @media ${device.tablet} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          `}
        >
          <div className="fade-intro">
            {/* <h1>{title}</h1> */}
            <h1>London</h1>
            <div
              css={css`
                font-size: clamp(1.15rem, 2.45621vw, 1.225rem);
              `}
            >
              {parse(content)}
            </div>
          </div>

          <div
            className="fade-intro"
            css={css`
              height: 50vh;
              width: 100%;
              padding-top: 1.5rem;
              margin-bottom: 1.5rem;
            `}
          >
            <GoogleMap />
          </div>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            flex-direction: column;
          `}
        >
          <div className="fade-intro">
            <h1>Bath</h1>
            <div
              css={css`
                font-size: clamp(1.15rem, 2.45621vw, 1.225rem);
              `}
            >
              56A Fitzroy House <br></br>
              Great Pulteney Street <br></br>Bath BA2 4DW
              <p
                css={css`
                  padding-top: 1.5rem;
                `}
              >
                <a href="tel:00441225800161">+44 (0)1225 800 161</a>
                <br></br>
                <a href="mailto:info@corrigangore.com">info@corrigangore.com</a>
              </p>
            </div>
          </div>

          <div
            className="fade-intro map"
            css={css`
              height: 50vh;
              width: 100%;
              padding-top: 1.5rem;
              margin-bottom: 1.5rem;
            `}
          >
            <GoogleMapBath />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDo4MQ==" }) {
      id
      title
      content
    }
  }
`
